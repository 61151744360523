import { faArrowPointer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const PromoButton = ({ matches }) => {
  const link =
    'https://api.whatsapp.com/send/?phone=62816332013&text=Halo+kakk,%20+Saya+tertarik+dengan+Tower+Fan+dan+Promonya+donkk...&app_absent=0';

  return (
    <Box sx={{ px: 2 }}>
      <Button
        data-aos="zoom-in"
        data-aos-delay={600}
        fullWidth
        variant="contained"
        color="secondary"
        sx={{
          borderRadius: '32px',
          mt: 2,
          fontWeight: 700,
          fontSize: matches
            ? responsiveFontSize(13) + 'vw'
            : maxFontSize(13) + 'px',
        }}
        startIcon={
          <FontAwesomeIcon style={{ fontSize: '13px' }} icon={faArrowPointer} />
        }
        onClick={() => window.open(link)}
      >
        Klik Disini dan Dapatkan Promo
      </Button>
    </Box>
  );
};
export default PromoButton;
