import { Box, Typography } from '@mui/material';
import { packaging } from '../assets';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const Delivery = ({ matches }) => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography
        data-aos="zoom-in"
        align="center"
        sx={{
          fontSize: matches
            ? responsiveFontSize(16) + 'vw'
            : maxFontSize(16) + 'px',
          fontWeight: 700,
          color: 'primary.main',
          lineHeight: 1.375,
        }}
      >
        Tidak perlu khawatir pesanan online <br /> Kami jamin PASTI SAMPAI ke
        rumah anda
      </Typography>
      <Typography
        data-aos="zoom-in"
        data-aos-delay={200}
        align="center"
        sx={{
          fontWeight: 500,
          fontSize: matches
            ? responsiveFontSize(10) + 'vw'
            : maxFontSize(10) + 'px',
          color: '#8F8F8F',
        }}
      >
        Status pengiriman dapat dicek kapanpun melalui website Nomor resi
        pengiriman barang akan dikirimkan via Whastapp kepada Anda.
      </Typography>
      <Box
        data-aos="zoom-in"
        data-aos-delay={300}
        component="img"
        src={packaging}
        sx={{ width: 1, borderRadius: '8px', mt: 2 }}
      />
    </Box>
  );
};
export default Delivery;
