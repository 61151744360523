import { Box, Button, Typography, useTheme } from '@mui/material';
import { shopeeLogo, tokopediaLogo, whatsappLogo } from '../assets';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const Contact = ({ links, matches }) => {
  const theme = useTheme();

  const merchants = [
    {
      name: 'Whatsapp',
      icon: whatsappLogo,
      color: 'success',
    },
    {
      name: 'Shopee',
      icon: shopeeLogo,
      color: 'info',
    },
    {
      name: 'Tokopedia',
      icon: tokopediaLogo,
      color: 'success',
    },
  ];

  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography
        data-aos="zoom-in"
        align="center"
        sx={{
          fontWeight: 600,
          fontSize: matches
            ? responsiveFontSize(14) + 'vw'
            : maxFontSize(14) + 'px',
        }}
      >
        Hubungi Kami
      </Typography>
      {merchants.map((merchant, index) => (
        <Button
          data-aos="zoom-in"
          data-aos-delay={100 * (index + 1)}
          key={index}
          fullWidth
          variant="contained"
          color={merchant.color}
          startIcon={
            <Box
              component="img"
              src={merchant.icon}
              sx={{
                height: matches
                  ? responsiveFontSize(34) + 'vw'
                  : maxFontSize(34) + 'px',
              }}
            />
          }
          onClick={() => window.open(links[index])}
          sx={{
            mt: 2,
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
            '&:hover': {
              boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
              bgcolor:
                merchant.color === 'success'
                  ? theme.palette.success.main
                  : theme.palette.info.main,
            },
            fontWeight: 600,
            fontSize: matches
              ? responsiveFontSize(18) + 'vw'
              : maxFontSize(18) + 'px',
          }}
        >
          {merchant.name}
        </Button>
      ))}
    </Box>
  );
};
export default Contact;
