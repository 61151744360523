import { Box, Typography, useTheme } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const Testimony = ({ images, matches, minHeight, maxHeight }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        bgcolor: '#FFF6F8',
        mt: 4,
        pt: 4,
        px: 2,
        borderRadius: '20px',
      }}
    >
      <Typography
        data-aos="zoom-in"
        data-aos-delay={200}
        align="center"
        sx={{
          color: 'primary.main',
          fontSize: matches
            ? responsiveFontSize(19) + 'vw'
            : maxFontSize(19) + 'px',
          fontWeight: 600,
          lineHeight: 1.25,
        }}
      >
        Testimoni dari Pelanggan Kami
      </Typography>
      <Typography
        data-aos="zoom-in"
        data-aos-delay={400}
        align="center"
        sx={{
          color: '#8F8F8F',
          fontWeight: 500,
          fontSize: matches
            ? responsiveFontSize(11) + 'vw'
            : maxFontSize(11) + 'px',
          lineHeight: 1.25,
        }}
      >
        Kami memberikan yang terbaik dalam melayani anda
      </Typography>
      <Box data-aos="zoom-in" data-aos-delay={600} sx={{ mt: 4, pb: 2 }}>
        <Carousel
          interval={5000}
          activeIndicatorIconButtonProps={{
            style: { color: theme.palette.primary.main },
          }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              sx={{
                width: 1,
                borderRadius: '10px',
                minHeight: matches ? minHeight : maxHeight,
              }}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};
export default Testimony;
