import { Container, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import {
  towerFanBenefit,
  towerFanDetail1,
  towerFanDetail2,
  towerFanHero,
  towerFanTestimony1,
  towerFanTestimony2,
  towerFanTestimony3,
  towerFanTestimony4,
} from '../assets';
import Benefit from '../components/Benefit';
import Contact from '../components/Contact';
import Delivery from '../components/Delivery';
import Discount from '../components/Discount';
import DiscountButton from '../components/DiscountButton';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ProductDetail from '../components/ProductDetail';
import Promo from '../components/Promo';
import Service from '../components/Service';
import Testimony from '../components/Testimony';

const TowerFan = () => {
  const matches = useMediaQuery('(max-width:444px)');

  const testimonyImages = [
    towerFanTestimony1,
    towerFanTestimony2,
    towerFanTestimony3,
    towerFanTestimony4,
  ];

  const productDetailImages = [towerFanDetail1, towerFanDetail2];

  const productDetailSpecs = [
    'Terdiri dari berbagai Mode: Swing, Timer, Speed, dll',
    'Dasar yang Stabil dan Kokoh',
    'Material ABS yang Ringan dan Kuat',
  ];

  const benefitDescs = [
    'Inovasi Thermosafe yang membuat Tower Fan X5 Aman & Tahan Lama',
    'Udara yang dihasilkan sejuk & dapat disesuaikan sehingga memenuhi kebutuhan anda',
    'Suara yang dihasilkan sangat tenang sehingga tidak akan menggangu aktivitas anda',
  ];

  const contactLinks = [
    'https://api.whatsapp.com/send/?phone=62816332013&text=Halo+kakk,%20+Saya+tertarik+dengan+Tower+Fan+dan+Promonya+donkk...&app_absent=0',
    'https://shopee.co.id/X5-Tower-Fan-Kipas-Lantai-Penyejuk-Pendingin-Australia-Murah-Berkualitas-FT20-17AR-i.280910121.7344319972?xptdk=0c5c80a6-3c1a-4bcb-9abb-d400dcf1173b',
    'https://www.tokopedia.com/danmogotofficial/tower-fan-ft20-17ar-kipas-berdiri-kipas-lantai-penyejuk-pendingin?extParam=ivf%3Dfalse',
  ];

  const [bottom, setBottom] = useState(16);

  window.onscroll = function (event) {
    // const myHtml = document.querySelector('html');
    // if (myHtml.offsetHeight + myHtml.scrollTop + 20 >= myHtml.scrollHeight)
    if (
      window.innerHeight + window.pageYOffset + 20 >=
      document.body.offsetHeight
    ) {
      setBottom(60);
    } else {
      setBottom(16);
    }
  };

  return (
    <Container maxWidth="xs" disableGutters>
      <Header image={towerFanHero} />
      <Promo matches={matches} title="tower fan" desc="sejuk & menenangkan" />
      <Testimony
        matches={matches}
        images={testimonyImages}
        minHeight="121.396vw"
        maxHeight="543.2px"
      />
      <ProductDetail
        matches={matches}
        desc="Panas panas gini enaknya di Tower Fan in aja"
        images={productDetailImages}
        specTitle="Spesifikasi Tower Fan X5 Australia"
        specDesc={productDetailSpecs}
        minHeight="127vw"
        maxHeight="574.3px"
      />
      <Benefit
        matches={matches}
        image={towerFanBenefit}
        title="Keuntungan Tower Fan X5
Australia Danmogot"
        descs={benefitDescs}
      />
      <Discount
        matches={matches}
        title="Tower Fan"
        normalPrice="1.100.000"
        discountPrice="900.000"
        superDiscountPrice="750.000"
      />
      <Service matches={matches} />
      <Delivery matches={matches} />
      <Contact matches={matches} links={contactLinks} />
      <Footer matches={matches} />
      <DiscountButton
        matches={matches}
        sx={{
          position: 'fixed',
          bottom: bottom,
          display: 'flex',
          justifyContent: 'center',
          width: 1,
          zIndex: 10,
          maxWidth: '444px',
          px: 4,
        }}
      />
    </Container>
  );
};
export default TowerFan;
