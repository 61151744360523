import { Box, Typography } from '@mui/material';
import { bankMerchants, cashOnDelivery } from '../assets';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const Service = ({ matches }) => {
  return (
    <Box sx={{ mt: 4, px: 4 }}>
      <Typography
        data-aos="zoom-out-up"
        align="center"
        sx={{
          fontSize: matches
            ? responsiveFontSize(12) + 'vw'
            : maxFontSize(12) + 'px',
          fontWeight: 600,
        }}
      >
        Melayani
      </Typography>
      <Box
        data-aos="zoom-out-up"
        data-aos-delay={200}
        component="img"
        src={bankMerchants}
        sx={{ width: 1, mt: 2 }}
      />
      <Box
        data-aos="zoom-out-up"
        data-aos-delay={400}
        component="img"
        src={cashOnDelivery}
        sx={{ width: 1, mt: 2, borderRadius: '12px' }}
      />
    </Box>
  );
};
export default Service;
