import { Box, Button } from '@mui/material';
import { faArrowPointer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const DiscountButton = ({ matches, ...props }) => {
  const link =
    'https://api.whatsapp.com/send/?phone=62816332013&text=Halo+kakk,%20+Saya+tertarik+dengan+Tower+Fan+dan+Promonya+donkk...&app_absent=0';

  return (
    <Box {...props}>
      <Button
        fullWidth
        variant="contained"
        color="success"
        sx={{
          fontSize: matches
            ? responsiveFontSize(13) + 'vw'
            : maxFontSize(13) + 'px',
          fontWeight: 700,
          borderRadius: '32px',
        }}
        startIcon={
          <FontAwesomeIcon
            style={{
              fontSize: matches
                ? responsiveFontSize(13) + 'vw'
                : maxFontSize(13) + 'px',
            }}
            icon={faArrowPointer}
          />
        }
        onClick={() => window.open(link)}
      >
        Klik Disini untuk Super Diskon
      </Button>
    </Box>
  );
};
export default DiscountButton;
