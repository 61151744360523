import { Box, Typography } from '@mui/material';
import { maxFontSize, responsiveFontSize } from '../utils/general';
import PromoButton from './PromoButton';

const Promo = ({ title, desc, matches }) => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography
        data-aos="zoom-in"
        data-aos-delay={200}
        align="center"
        sx={{
          color: 'primary.main',
          fontWeight: 700,
          fontSize: matches
            ? responsiveFontSize(28) + 'vw'
            : maxFontSize(28) + 'px',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Typography>
      <Typography
        data-aos="zoom-in"
        data-aos-delay={400}
        align="center"
        sx={{
          fontWeight: 500,
          fontSize: matches
            ? responsiveFontSize(20) + 'vw'
            : maxFontSize(20) + 'px',
          textTransform: 'capitalize',
        }}
      >
        {desc}
      </Typography>
      <PromoButton matches={matches} />
    </Box>
  );
};
export default Promo;
