import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import 'aos/dist/aos.css';

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#41A2DB',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F8AF16',
      contrastText: '#fff',
    },
    success: {
      main: '#3CBB38',
      contrastText: '#fff',
    },
    info: {
      main: '#F5F5F5',
      contrastText: '#EE4D2D',
    },
    background: {
      default: 'fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          borderRadius: '8px',
          padding: '16px',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
