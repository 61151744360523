import Carousel from 'react-material-ui-carousel';
import { Box, Grid, Typography } from '@mui/material';
import { maxFontSize, responsiveFontSize } from '../utils/general';

const ProductDetail = ({
  desc,
  images,
  specTitle,
  specDesc,
  matches,
  minHeight,
  maxHeight,
}) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ px: 2 }}>
        <Typography
          data-aos="zoom-in"
          align="center"
          sx={{
            color: 'primary.main',
            fontWeight: 600,
            fontSize: matches
              ? responsiveFontSize(19) + 'vw'
              : maxFontSize(19) + 'px',
            lineHeight: 1.25,
          }}
        >
          Detail Produk
        </Typography>
        <Typography
          data-aos="zoom-in"
          data-aos-delay={200}
          align="center"
          sx={{
            color: '#B1A5A8',
            fontWeight: 500,
            fontSize: matches
              ? responsiveFontSize(10) + 'vw'
              : maxFontSize(10) + 'px',
            lineHeight: 1.25,
          }}
        >
          {desc}
        </Typography>
      </Box>
      <Box sx={{ mt: 4, bgcolor: 'primary.main', py: 4, px: 2 }}>
        <Box data-aos="zoom-in" data-aos-delay={400}>
          {images.length > 0 && (
            <Carousel
              interval={5000}
              activeIndicatorIconButtonProps={{
                style: { color: '#fff' },
              }}
            >
              {images.map((image, index) => (
                <Box
                  key={index}
                  component="img"
                  src={image}
                  sx={{
                    width: 1,
                    borderRadius: '20px',
                    minHeight: matches ? minHeight : maxHeight,
                  }}
                />
              ))}
            </Carousel>
          )}
        </Box>

        <Typography
          data-aos="zoom-in-right"
          sx={{
            fontWeight: 600,
            color: 'primary.contrastText',
            fontSize: matches
              ? responsiveFontSize(18) + 'vw'
              : maxFontSize(18) + 'px',
            mt: 2,
          }}
        >
          {specTitle}
        </Typography>
        <Box sx={{ my: 2 }}>
          <Grid container rowSpacing={2}>
            {specDesc.map((spec, index) => (
              <Grid
                data-aos="zoom-in-right"
                data-aos-delay={100 * (index + 1)}
                item
                container
                columnSpacing={2}
                xs={12}
                key={index}
              >
                <Grid item xs="auto">
                  <Box
                    sx={{
                      width: matches
                        ? responsiveFontSize(20) + 'vw'
                        : maxFontSize(20) + 'px',
                      height: matches
                        ? responsiveFontSize(20) + 'vw'
                        : maxFontSize(20) + 'px',
                      color: 'secondary.contrastText',
                      bgcolor: 'secondary.main',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: matches
                          ? responsiveFontSize(12) + 'vw'
                          : maxFontSize(12) + 'px',
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography
                    sx={{
                      color: 'primary.contrastText',
                      fontWeight: 500,
                      fontSize: matches
                        ? responsiveFontSize(12) + 'vw'
                        : maxFontSize(12) + 'px',
                    }}
                  >
                    {spec}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default ProductDetail;
