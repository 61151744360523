import { Box, Typography } from '@mui/material';
import { maxFontSize, responsiveFontSize } from '../utils/general';
import DiscountButton from './DiscountButton';

const Discount = ({
  title,
  normalPrice,
  discountPrice,
  superDiscountPrice,
  matches,
}) => {
  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        align="center"
        sx={{
          color: 'primary.main',
          fontSize: matches
            ? responsiveFontSize(19) + 'vw'
            : maxFontSize(19) + 'px',
          fontWeight: 700,
          lineHeight: 1.25,
        }}
      >
        Dapatkan {title}
      </Typography>
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        align="center"
        sx={{
          color: 'primary.main',
          fontSize: matches
            ? responsiveFontSize(19) + 'vw'
            : maxFontSize(19) + 'px',
          fontWeight: 700,
          lineHeight: 1.25,
        }}
      >
        Harga Special
      </Typography>
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        align="center"
        sx={{
          color: '#8F8F8F',
          fontWeight: 500,
          fontSize: matches
            ? responsiveFontSize(10) + 'vw'
            : maxFontSize(10) + 'px',
        }}
      >
        Kita yang rugi atau kalian yang rugi ?{' '}
      </Typography>
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        data-aos-delay={100}
        align="center"
        sx={{
          fontSize: matches
            ? responsiveFontSize(10) + 'vw'
            : maxFontSize(10) + 'px',
          fontWeight: 500,
          textDecoration: 'line-through',
          mt: 1,
        }}
      >
        Harga Normal: Rp{normalPrice}
      </Typography>
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        data-aos-delay={200}
        align="center"
        sx={{
          color: 'secondary.main',
          fontSize: matches
            ? responsiveFontSize(10) + 'vw'
            : maxFontSize(10) + 'px',
          fontWeight: 500,
          textDecoration: 'line-through',
        }}
      >
        Harga Diskon: Rp{discountPrice}
      </Typography>
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        data-aos-delay={400}
        align="center"
        sx={{
          fontWeight: 800,
          color: 'info.contrastText',
          fontSize: matches
            ? responsiveFontSize(19) + 'vw'
            : maxFontSize(19) + 'px',
          mt: 1,
        }}
      >
        Harga Super Diskon
      </Typography>
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        data-aos-delay={400}
        align="center"
        sx={{
          fontWeight: 800,
          color: 'info.contrastText',
          fontSize: matches
            ? responsiveFontSize(50) + 'vw'
            : maxFontSize(50) + 'px',
        }}
      >
        Rp{superDiscountPrice}
      </Typography>
      <DiscountButton
        data-aos="zoom-in-up"
        data-aos-duration={800}
        data-aos-delay={500}
        matches={matches}
        sx={{ mt: 1, px: 2 }}
      />
      <Typography
        data-aos="zoom-in-up"
        data-aos-duration={800}
        data-aos-delay={500}
        align="center"
        sx={{
          color: '#8F8F8F',
          fontWeight: 500,
          fontSize: matches
            ? responsiveFontSize(10) + 'vw'
            : maxFontSize(10) + 'px',
          mt: 1,
        }}
      >
        Kapan lagi bisa hemat lagi dan lagi untuk pemesanan di bulan ini
        Silahkan klik untuk memesan produk ini dan kami akan segera mengirimkan
        ke rumah Anda.
      </Typography>
    </Box>
  );
};
export default Discount;
